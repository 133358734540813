<template lang="pug">
.om-tactic-recommendation-component
  .row(v-if="false" style="height: calc(100vh - 8rem)")
    loading-logo
  template(v-else)
    template(v-if="showEasyTactics")
      .row.om-easy-tactic
        .col-12
          .om-tactic-recommendation-top
            .container
              .row
                .col-12.d-flex.align-items-center.justify-content-center.text-center
                  .om-tactic-recommendation-title-wrapper
                    wizard-title.om-tactic-recommendation-title.mb-4(
                      v-html="$t('onboarding.wizard.pages.recommendation.title', { businessName: businessName })"
                    )
      .container
        .row.justify-content-center(
          key="usecases"
          data-track-property="component:easy-tactics"
          :ref="paletteRef"
        )
          .col-12.mb-5
            om-heading.text-center.mb-5(h3) {{ $t('onboarding.wizard.pages.recommendation.blocks.easy.title', { businessName: businessName }) }}
          .col-12
            recommendation-card(
              v-for="(tactic, index) in recommendedEasyTactics"
              v-if="!customThemeLoading"
              @observable="addObservable($event.$el)"
              @inited="updateDimensions"
              @showSimilarTemplates="showSimilarTemplates"
              @showTemplatePreview="showTemplatePreview"
              @learnMore="openUrlInBlank"
              :dimensions="boxDimensions"
              :key="tactic._id"
              :useCase="tactic"
              :theme="selectedTheme"
              :customTheme="customTheme"
              :index="index"
              @shown="onCardShow"
            )
          .col-12.mb-5.recommendation-optimization-block-heading
            om-heading(h3) {{ $t('onboarding.wizard.pages.recommendation.blocks.optimize.title', { businessName: businessName }) }}
          .col-12(v-for="(tactic, index) in fixOptimizationTactics")
            recommendation-card(
              v-if="!customThemeLoading"
              :fixTactic="tactic"
              @learnMore="openUrlInBlank"
              :key="index"
              :index="index"
              @selectFixTactic="onTacticSelection(index)"
            )
        .row.om-tactic-more-advanced-tactics(
          v-if="showTacticsLibraryBanner"
          :style="'margin-bottom: 4rem'"
          data-track-property="component:more-advanced-tactics"
        )
          img.om-tactic-more-tactics-bg-graph(
            src="@/assets/admin/img/wizard/wizard_more_tactics_bg.png"
          )
          .col-8
            .text-line-1 {{ $t('onboarding.wizard.pages.recommendation.tacticLibrary.textLine1') }}
            .text-line-2 {{ $t('onboarding.wizard.pages.recommendation.tacticLibrary.textLine2') }}
          .col-4.d-flex.justify-content-end.align-items-center
            om-button(
              primary
              medium
              @click="openUrlInBlank(tacticLibraryUrl)"
              data-track-property="change:showTacticsLibrary"
            )
              UilLightbulbAlt.mr-2(size="1.5rem")
              span.pr-1 {{ $t('onboarding.wizard.pages.recommendation.tacticLibrary.button') }}
  MoreDetails(
    :aiTypes="aiTypes"
    :about="about"
    @track="onTrack($event)"
    :type="getOptimizationType(about)"
  )
  Book(
    :about="about"
    @track="onTrack($event)"
    :type="getOptimizationType(about)"
    @close="openDetailsModal"
  )
  DcPersonalization(@close="onDeselect")
  NewCampaign(@close="openDetailsModal")
  LastRequestDateOld
</template>

<script>
  import { UilLightbulbAlt } from '@iconscout/vue-unicons';
  import RecommendationCard from '@/components/Tactics/RecommendationCard.vue';
  import TemplatePreview from '@/components/Modals/TemplatePreview';
  import AdvancedTacticsRecommendation from '@/components/Tactics/AdvancedTacticsRecommendation.vue';
  import AdvancedTacticCard from '@/components/Tactics/AdvancedTacticCard.vue';

  import staticTactic from '@/mixins/tactics/staticTactic';
  import observableCollectionMixin from '@/mixins/observableCollection';
  import paletteMixin from '@/mixins/palette';
  import ssrMixin from '@/mixins/ssr';
  import aiFeaturesMixin from '@/mixins/aiFeatures';

  import { tacticLibraryUrl, fixOptimizationTactics } from '@/utils/tactics';
  import { mapState, mapGetters } from 'vuex';
  import commonFlowsMixin, { OPTIMIZATION_TYPES } from '@/mixins/commonFlows';

  export default {
    components: {
      UilLightbulbAlt,
      RecommendationCard,
      TemplatePreview,
      AdvancedTacticsRecommendation,
      AdvancedTacticCard,
      WizardTitle: () => import('@/components/Wizard/Title'),
      AITacticCard: () => import('@/components/Tactics/AITacticCard.vue'),
    },
    mixins: [
      commonFlowsMixin,
      staticTactic,
      paletteMixin,
      ssrMixin,
      observableCollectionMixin,
      aiFeaturesMixin,
    ],

    props: {
      showEasyTactics: {
        type: Boolean,
        default: true,
      },
      showAdvancedTactics: {
        type: Boolean,
        default: true,
      },
      showTacticsLibraryBanner: {
        type: Boolean,
        default: true,
      },
      recommendedEasyTactics: {
        type: Array,
        default: () => [],
      },
      selectedTheme: {
        type: String,
        default: '',
      },
      customTheme: {
        type: Object,
        default: null,
      },
      tacticsLoading: {
        type: Boolean,
        default: true,
      },
      customThemeLoading: {
        type: Boolean,
        default: false,
      },
      shownLimit: {
        type: Number,
        default: 0,
      },
    },

    data() {
      return {
        fixTacticLoading: true,
        shown: [],
        fixOptimizationTactics,
        about: 'sab',
      };
    },

    computed: {
      ...mapState(['account']),
      ...mapGetters(['getAccountBusinessName', 'domains', 'getActiveShopifyDomains']),
      tacticLibraryUrl() {
        return tacticLibraryUrl[this.$i18n.locale];
      },
      businessName() {
        return this.getAccountBusinessName || 'you';
      },
      showFixTactic() {
        if (this.domains?.length !== 1) return false;
        return !!this.getFixTactic && this.getActiveShopifyDomains?.length === 1;
      },
      domain() {
        return this.domains?.[0]?.domain;
      },
      faviconUrl() {
        return `https://t0.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=http://${this.domain}&size=32`;
      },
    },

    created() {
      this.initiate();
    },

    methods: {
      async initiate() {
        await this.prepareFixTactic();
        this.fixTacticLoading = false;
      },

      openUrlInBlank(url) {
        window.open(url, '_blank');
      },

      showSimilarTemplates(params) {
        this.$emit('showSimilarTemplates', params);
      },

      async showTemplatePreview(params) {
        this.$emit('showTemplatePreview', params);
      },
      onCardShow(card) {
        this.shown.push(card);
        this.keepShownLimit();
      },
      keepShownLimit() {
        if (!this.shownLimit) return;
        let count = 0;
        this.shown.forEach((card) => {
          if (card?.template && card?.canShow) count++;
          if (card?.canShow && count > this.shownLimit) card.canShow = false;
        });
      },
      onDeselect() {
        this.about = 'sab';
      },
      onTacticSelection(index) {
        this.about = OPTIMIZATION_TYPES[index] ?? 'sab';
        this.openDetailsModal();
      },
    },
  };
</script>

<style lang="sass">
  @import '@/sass/variables/_colors.sass'
  .site-url
    font-size: 1.5rem
    font-style: italic
  .om-tactic-recommendation
    &-title
      font-size: 2.25rem
    &-subtitle
      font-size: 1.25rem
    &-component
      .favicon-url
        display: flex
        align-items: center
        .site-favicon-wrapper
          display: flex
          align-items: center
          justify-content: center
          width: 2.5rem
          height: 2.5rem
          border: 2px solid $om-peach-200
          background: white
          border-radius: 100%
      .recommendation-optimization-block-heading
        margin-top: 3rem
    &-happy-monk
      margin-right: 6rem
      height: 20.5rem
      transform: scaleX(-1)
    &-top
      padding: 1.25rem 0
      margin-top: 3.5rem
      margin-bottom: 1.5rem
      background: transparent
</style>
